.launcher-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
}

.title {
    font-size: 75px;
    font-weight: 800;
    margin-bottom: 15px;
    margin-top: 60px;
    position: relative;
}

.title-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tip {
    max-width: 400px;
    font-size: 16px;
}

.title-section img {
    height: 25px;
    margin-left: 10px;
}

.group-select {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 auto;
}

.group-select > div {
  width: 100%;
}

.next-arrow {
    align-items: center;
    display: flex;
    margin-left: 80%;
    position: absolute;
}

.back-arrow {
    align-items: center;
    display: flex;
    margin-left: 3%;
    position: absolute;
}

.material-container-launcher {
    margin-left: 50px;
    margin-right: 50px;
    background-color: #e9e9e9;
    padding: 25px;
    width: 40%;
    border-radius: 10px;
    margin-bottom: 25px;
}

.mode-container {
    display: flex;
    flex-direction: column;
}

.mode-wrapper {
    display: flex;
    justify-content: center;
}

.explorer-link {
    font-weight: 800;
    cursor: pointer;
}

.button-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.button-column-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.selection-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

#tutorial-hack .selection-area {
    margin-top: 0px;
}

.button-column h1 {
    margin-bottom: 0px;
}

.disabler {
    font-weight: 800;
    background-color: lightgray;
    border-radius: 1em;
    padding: 1em;
    color: #fff;
    margin-top: 10px;
}

.disabled-text {
    color: lightgray;

}

.small-font {
    font-size: 25px;
    font-weight: 800;
}

.medium-font {
    font-size: 45px;
    font-weight: 800;
}

.experience img {
    margin: 25px;
    max-width: 350px;
    margin-bottom: 0px;
}

.experience {
    transition: 0.2s;
}

.experience h4 {
    margin: 0px;
}

.experience:hover {
    transform: scale(1.05);
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
}

#review-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.review-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex: 0 1 auto; */
}

.review-slot h2 {
    margin-right: 10px;
}

.cat-name {
    font-weight: 400;
}

.experience-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-h4 {
    margin-top: 5px;
    margin-bottom: 30px;
}

.modal-button-group {
    margin-top: 30px;
}

.hands-preview {
    max-height: 75px;
}

.advanced-button {
    display: flex;
    justify-content: center;
}

#settings-title {
    margin-bottom: 0px;
}

.category-title img {
    width: 18px;
    margin-left: 8px;
}

.category-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

#settings-block {
    margin-top: 50px;
    background-color: #fff;
    padding: 20px;
}

#tutorial-hack {
    display: flex;
    margin-top: 0px;
}

.button-stretch {
    width: 100%;
}

#loading-gif {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);    
}

.modal-lesson {
    margin-bottom: 8px;
}

.supplement-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.materials-supplements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 25px;
    height: 100%;
    /* background-color: #e9e9e9; */
}

.materials-supplements h1 {
    font-size: 40px;
    margin-bottom: 25px;
    color: #878787
}

h3 {
    margin-top: 0px;
    color: #878787
}

.supplement-column {
    background-color: #d3d3d3;
    padding: 10px;
    border-radius: 15px;
    width: 40%;
}

.supplement-packs {
    width: 40%;
    background-color: #e9e9e9;
}

#material-review-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

#item-shape-review-section {
    display: flex;
    flex-direction: column;
}

#material-review-section img {
    margin: 25px;
}

#material-warning {
    color: #fff;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#material-warning h4 {
    color: #db3236;
    border-radius: 15px;
    margin: 0px;
}

#material-warning img {
    height: 25px;
    margin: 5px;
}

#text-review {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 25px;
}

.material-review-screen img {
    height: 75px;
}

.review-slot h2 {
    background-color: #d9d9d9;
    padding: 10px;
    border-radius: 15px;
}

.no-groups-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-badge {
    background-color: #1aa260;
    color: white;
    font-weight: bold;
    font-size: 15px;
    padding: 7px;
    border-radius: 50px;
}

.supplement-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden {
    visibility: hidden;
}
