.centered-loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
}

.loading-message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-message-box img {
    height: 100px;
    margin: 15px;
    opacity: 0.25;
}

.loading-message-box h4 {
    margin: 0px;
}