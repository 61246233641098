/* 1/1/20 TODO: either prefix these classes with otaku-, or figure out a resilient way to negate css bleed from other components */

#screen { /* https://stackoverflow.com/questions/20590239/maintain-aspect-ratio-of-div-but-fill-screen-width-and-height-in-css */
    width: 100vw; 
    height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
    background: white;
    max-height: 100vh;
    max-width: 177.78vh; /* 16/9 = 1.778 */
    margin: auto;
    top:0;bottom:0; /* vertical center */   
    left:0;right:0; /* horizontal center */
    overflow: hidden; 
}

.parent {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border: dashed; 
    border-color: red; */
}

.container {
    width: 100%;
    height: 100%;
}

.child {
    position: absolute;
}

.canvas {
    width: 100%;
    height: 100%;
}

.hidden-element {
    display: none;
}

.text-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px; /* this is hardcoded because of the SVG hack.  use 'size' when declaring the element to change 'font size' */
}

#grid {
    height: 100%;
    width: 100%;
    position: absolute; 
}



