input {
    font-size: 20px;
    font-weight: 400;
    width: 300px;
    border-radius: 10px;
    padding: 9px;
    margin-right: 10px;
}

input:focus {
    outline-width: 0;
}

.photo-edit-container input {
    padding: 2px;
    border-radius: 6px;
}

.all-group-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px;
    width: 50%;
}

.all-group-container h1 {
    margin-bottom: 0;
}

.group-selector {
    align-items: center;
    border-radius: 1em;
    background-color: lightgrey;
    display: flex;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
    justify-content: space-between;
}

.group-creator {
    display: flex;
    align-items: center;
}

.group-attribute {
    font-weight: 800;
    font-size: 25px;
    margin: 10px;
}

.group-buttons {
    display: flex;
    align-items: center;
}

.group-link {
    background-color: blue;
    color: white;
    padding: 10px;
}

.playthrough-selector {
    background-color: lightgrey;
    padding: 20px;
    margin-top: 40px;
    border-radius: 1em;
}

.photos-link {
    background-color: green;
    color: white;
    padding: 10px;
}

.info-bar {
    display: flex;
}

.photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
    border-radius: 1em;
    margin-top: 40px;
}

.photo {
    max-width: 750px;
    border-radius: 15px;
}

.edit-container {
    display: flex;
    align-items: center;
}

.photo-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.photo-info h2 {
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.photo-edit-container {
    display: flex;
    align-items: center;
}

.character-warning {
    color: #DB4437;
    padding: 0px;
    margin: 0px;
}

.photo-warning {
    margin-bottom: 10px;
}

.group-info-header {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.info-piece {
    margin-right: 10px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    font-weight: 800;
}

.blocked-text {
    background-color: #979394;
    padding: 5px;
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
}

#photo-info {
    margin-top: 25px;
}

#button-hack {
    margin-left: 0 !important
}

.playthrough-selector h3 {
    margin-left: 10px;
}

#loading-gif {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
}


