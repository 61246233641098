.rectangle {
    background-color: #332c2e;
    color: #fff;
    user-select: none;
    display: inline-block;
    align-items: center;
    font-weight: 800;
    transition: 0.2s;
    margin: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.button-img {
    margin-left: 0.5em;
    height: 1.25em !important;
}

.flipped-img {
    margin-left: 0;
    margin-right: 0.5em;
    height: 1em !important;
    transform: rotate(180deg)
}

.reverse {
    margin-right: 0.5em;
    flex-direction: row-reverse;
}

.reverse .button-img{
    margin-right: 0.5em;
    margin-left: 0;
    flex-direction: row-reverse;
}

.rectangle:hover {
    opacity: 0.65;
    transform: scale(1.1);
    transition-duration: 100ms;
}

.xs {
    font-size: 14px;
    padding: 0.5em;
    border-radius: 0.25em 0.75em;
}

.sm {
    font-size: 20px;
    padding: 0.5em;
    border-radius: 0.25em 0.75em;
}

.md {
    font-size: 30px;
    padding: 0.75em;
    border-radius: 0.25em 0.75em;
}

.lg {
    font-size: 40px;
    padding: 0.75em;
    border-radius: 0.25em 0.75em;
}

.xl {
    font-size: 65px;
    padding: 0.75em;
    border-radius: 0.25em 0.75em;
}

.selected {
    background-color: #4885ed !important;
}

.light {
    background-color: #a3c2f6;
}

.blue {
    background-color: #4885ed;
}

.red {
    background-color: #DB4437;
}

.green {
    background-color: #3cba54;
}

.gray {
    background-color: #a6a6a6;
}

.orange {
    background-color: #FFA500;
}

img {
    border-radius: 0px;
}
