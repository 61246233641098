#firewall-test {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message {
  display: flex;
  flex-direction: column;
}

.icon-text-message {
  align-items: center;
  display: flex;
  justify-content: center;
}

.message-icon {
  height: 35px;
  margin-right: 10px;
}

#success-text {
  color: #3cba54;
  font-size: 20px;
  font-weight: bold;
}

#fail-text {
  color: #DB4437;
  font-size: 20px;
  font-weight: bold;
}

#instructions {
  user-select: text;
  cursor: text;
  margin-top: 10px;
}