#screen-overlay {
    /* CHECK: @sh0shinsha this is a copy of the screen id in otaku.css */
    width: 100vw; 
    height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
    max-height: 100vh;
    max-width: 177.78vh; /* 16/9 = 1.778 */
    margin: auto;
    top:0;bottom:0; /* vertical center */   
    left:0;right:0; /* horizontal center */
    
    /* NOTE: added 8/12/19, this properly centers scree-overlay */
    position: absolute;

    overflow: hidden;
}



