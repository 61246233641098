.user-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 30px;
    width: 100%;
}

.user-section {
    width: 25%;
    margin-right: 25px;
}

.section-container {
    background-color: lightgray;
    padding: 40px;
    border-radius: 25px;
}

.section-container h3 {
    color: black;
    margin-top: 15px;
}

.my-profile-field {
    background-color: gray;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
}

.all-user-container {
    display: flex;
    align-items: center;
    background-color: lightgray;
    padding: 10px;
    border-radius: 15px;
    margin-top: 15px;
}

.user-form-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: center;
}

.form-field-warning {
    color: white;
    background-color: red;
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    border-radius: 5px;
}

.user-subsection {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.user-subsection h2 {
    margin: 0;
}

.user-container h5 {
    margin: 0;
    margin-top: 2%;
}

.editable-profile-field {
    display: flex;
}

.editable-profile-field h3 {
    width: 95%;
}

#dropdown-container {
    margin: 15px;
}

