@font-face {
    font-family: 'Digital';
    src: url('../fonts/Digital-Dismay.otf'); 
  }

body {
    margin: 0;
    user-select: none;
    cursor: default;
    background-color: #f6f6f6;
    font-family: 'Open Sans', sans-serif;
}   


a {
    text-decoration: none;
}

.btn {
    margin: 5px;
  }

/* LOGIN */

#login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#browser-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#browser-message h5 {
    color: #878787
}

#chrome-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

#chrome-link img {
    height: 75px;
    margin: 15px;
}

#login-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}

/* NAVBAR */

#nav-bar {
    background-color: #332c2e;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
}

#nav-bar a {
    color: white;
    font-weight: bold;
    margin-right: 40px;
    align-items: center
}

#nav-logo {
    height: 40px;
    margin-left: 20px;
}

#right-nav {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.big-nav-element {
    font-size: 25px;
}

.nav-element  {
    transition: all .2s ease-in-out;
}

.nav-element:hover {
    opacity: 0.65;
    transform: scale(1.2);
    transition-duration: 75ms;
}

.static-nav-element {
    color: lightslategray;
    margin-right: 20px;
    font-size: 10px;
}

#nav-buttons {
   display: flex;
}

#nav-buttons a {
    margin-right: 0px;
 }

 .nav-info-element {
     display: flex;
     align-items: center;
 }

 .nav-info-element img {
     height: 15px;
     margin-right: 8px;
     opacity: .5;
 }


/* HOME */

#home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    margin: 0px;
    background-image: url(../img/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

#home-container hr {
    opacity: 0.5;
    width: 75%;
}

#home-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#home-selection-text {
    margin-top: 50px;
}
#home-logo {
    width: 750px;
    margin-top: 50px;
    margin-bottom: 50px;
}

#home-logo-small {
    width: 250px;
    margin-top: 75px;
    margin-bottom: 25px;
    margin-top: 0px;
}

#offline {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 800;
}

#offline-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}



/* OTHER */

#dropzone-container .dropzone {
    font-family: 'Ubuntu';
}

.ui-highlight {
    color: red;
    border: solid;
    font-weight: 800;
}

.breadcrumbs {
    display: flex;
}

.crumb {
    margin-right: 10px;
}

.active-crumb {
    border: solid;
}

#mode-container {
    display: flex;
}

#confirm-container a {
    color: green;
}

.default-selection {
    border: dashed;
}

.info-button {
    height: 25px;
    margin-left: 10px;
}

.dropdown-element {
    align-items: center;
    display: flex;
}

.dropdown-element a {
    color: blue;
    font-size: 10px;
}

.elliot-chirp {
    color: orange;
    font-size: 14px;
}

#nav-logo {
    border-radius: 0px !important;
}

#reload-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: darkgray;
    padding: 50px;
}

#reload-error-container h3 {
    color: #fff;
}

#html-error-note {
    color: #A9A9A9;
    font-size: 22px;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.centered-loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
}

#home-screen-logo {
    height: 175px;
    margin-top: 75px;
}