.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5)
}

.error-detail {
  color: white;
  background-color: black;
  padding: 3px;
  border-radius: 5px;
}



