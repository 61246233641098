#photo-upload-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
}

#photo-upload-nav img {
    height: 50px;
}

#upload-button-wrapper {
    display: flex;
    justify-content: center;
}

#photo-upload-main {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#photo-upload-message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
}

#placeholder-img {
    opacity: 0.2;
}

.photo-upload-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photo-upload-message img {
    height: 150px;
}