.title-text {
    background-color: blue;
    color: #fff;
    font-size: 3vw;
    padding: 1vw;
    border-radius: 1vw;
    font-weight: 800;
}

.text-hack-container {
    display: flex;
    margin-right: 95vw;
    margin-bottom: 52vw;
}