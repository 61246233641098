#player-background {
    background-color: #000;
    height: 100vh;
    width: 100vw;
}

#inspector-container {
    position: absolute;
    background-color: blue;
    padding: 25px;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
}