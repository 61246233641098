#confirmation-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#drag-gif img {
    height: 400px;
    margin: 50px;
}